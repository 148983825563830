import config from "@lib/amplifyConfig";
import { logger } from "@starlight-core/core/lib/logger";
import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from "aws-amplify/auth";
import { atom } from "nanostores";

Amplify.configure(config, { ssr: true });

let user, attributes, tokens;
try {
  tokens = await fetchAuthSession();
  if (!tokens.userSub) {
    logger.info("Not signed in");
  } else {
    user = await getCurrentUser();
    attributes = await fetchUserAttributes();
  }
} catch (e) {
  logger.warn("Failed to fetch user", e as Error);
}

export const $user = atom({ user: { ...user, attributes }, tokens });
